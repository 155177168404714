// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-bosa-migros-supply-chain-tsx": () => import("/vercel/workpath0/src/pages/bosa-migros-supply-chain.tsx" /* webpackChunkName: "component---src-pages-bosa-migros-supply-chain-tsx" */),
  "component---src-pages-index-tsx": () => import("/vercel/workpath0/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-migipedia-marketing-tsx": () => import("/vercel/workpath0/src/pages/migipedia/marketing.tsx" /* webpackChunkName: "component---src-pages-migipedia-marketing-tsx" */),
  "component---src-pages-migipedia-performance-driven-campaigns-tsx": () => import("/vercel/workpath0/src/pages/migipedia/performance-driven-campaigns.tsx" /* webpackChunkName: "component---src-pages-migipedia-performance-driven-campaigns-tsx" */),
  "component---src-pages-migipedia-technology-tsx": () => import("/vercel/workpath0/src/pages/migipedia/technology.tsx" /* webpackChunkName: "component---src-pages-migipedia-technology-tsx" */),
  "component---src-pages-migipedia-usability-tsx": () => import("/vercel/workpath0/src/pages/migipedia/usability.tsx" /* webpackChunkName: "component---src-pages-migipedia-usability-tsx" */),
  "component---src-pages-migros-supply-chain-tsx": () => import("/vercel/workpath0/src/pages/migros-supply-chain.tsx" /* webpackChunkName: "component---src-pages-migros-supply-chain-tsx" */),
  "component---src-pages-migusto-tsx": () => import("/vercel/workpath0/src/pages/migusto.tsx" /* webpackChunkName: "component---src-pages-migusto-tsx" */),
  "component---src-pages-subsidia-tsx": () => import("/vercel/workpath0/src/pages/subsidia.tsx" /* webpackChunkName: "component---src-pages-subsidia-tsx" */),
  "component---src-pages-tauschboerse-tsx": () => import("/vercel/workpath0/src/pages/tauschboerse.tsx" /* webpackChunkName: "component---src-pages-tauschboerse-tsx" */)
}

